import '@babel/polyfill'
import Vue from 'vue';
import vuetify from './plugins/vuetify'
Vue.config.productionTip = false

import Vuex from 'vuex';
Vue.use(Vuex);
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

import CommunicationSettings from './communication/CommunicationSettings';
declare var global : any;

global.$keycloak = new global.Keycloak({
    url: CommunicationSettings.authApiUrl(),
    realm: CommunicationSettings.realm(),
    clientId: 'license-manager',
  })


import vuejsKeycloakSecurity from './components/vuejs-keycloak/security'
import vuejsKeycloak from './components/vuejs-keycloak/'


router.beforeEach((to, from, next) => {
  // clear any progress
  if (to.meta.requiresAuth === false) {
    next();
  } else {
    const auth = vuejsKeycloak.state.security.auth
    if (!auth.authenticated) {
      console.log("MUST INIT SECURITY " + window.location.href);
      vuejsKeycloakSecurity.init(next //, to.meta.roles
        ).then(function(value) {
        if (router.mode == 'hash') {
          // returned url is /#/devices&state=...&session...
          // need to make it compotible with vue router which does not expect the first &
          let hashPos = window.location.href.indexOf('#');
          let hashString = window.location.href.slice(hashPos + 1)
          next({ path: hashString })
        } else {
          next();
        }
      }).catch( function(error){
        next({ name: 'unauthorized' })
      });
    }
    else {
      if (to.meta.roles) {
        if (vuejsKeycloakSecurity.roles(to.meta.roles[0])) {
          next()
        }
        else {
          next({ name: 'unauthorized' })
        }
      }
      else {
        next()
      }
    }
  }
})

const store = new Vuex.Store({
  //modules: modulelist
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
