

  import LicenseManagerAxiosInstance from '../communication/axios/implementation/LicenseManagerAxiosInstance'
  import { FetchOptions } from '../communication/axios/ILicenseManagerAxiosInstance'
  import uuidv4 from 'uuid/v4'
  const toLower = text => {
    return text.toString().toLowerCase()
  }

import { Vue, Component, Watch } from 'vue-property-decorator'

interface LicenseData  {

}

// The @Component decorator indicates the class is a Vue component
@Component({
})
export default class LicenseView extends Vue{
    search: string = ''
    dialog: boolean = false
    loading: boolean = true
    totalLicenses: number = 0

    newLicenseModal: boolean = false
    latestLicenses: any = [{}]

    editedIndex: number = -1
    editedItem: any = { vpnStartDateTime: null, vpnEndDateTime: null }
    pagination: any = { page: 1, itemsPerPage: 10 }
    headers: any[] = [
        {
            text: 'ID',
            align: 'left',
            sortable: true,
            value: 'id'
        },
        {
            text: 'Realm',
            align: 'left',
            sortable: true,
            value: 'realm'
        },
        {
            text: 'Organization',
            align: 'left',
            sortable: true,
            value: 'orgResourceId'
        },
        {
            text: 'Activation key',
            align: 'left',
            width: '200px',
            sortable: true,
            value: 'activationKey'
        },
        {
            text: 'Logical ID',
            align: 'left',
            sortable: true,
            value: 'logicalId'
        },
        {
            text: 'Device Name',
            align: 'left',
            sortable: true,
            value: 'label'
        },
        {
            text: 'Customer',
            align: 'left',
            sortable: true,
            value: 'clientName'
        },
        {
            text: 'Notes',
            align: 'left',
            width: '200px',
            sortable: true,
            value: 'notes'
        },
        {
            text: 'Device Alias',
            align: 'left',
            sortable: true,
            value: 'alias'
        },
        {
            text: 'Serial No.',
            align: 'left',
            sortable: true,
            value: 'serialNumber'
        },
        {
            text: 'API Key',
            align: 'left',
            sortable: true,
            value: 'apiKey'
        },
        {
            text: 'VPN',
            align: 'left',
            sortable: true
        },
        {
            text: 'VPN Key',
            align: 'left',
            sortable: true,
            value: 'vpnKey'
        },
        {
            text: 'Used in trial',
            align: 'left',
            sortable: false,
            value: 'usedInTrial'
        },
        { 
            text: 'Actions',
            value: 'actions',
            sortable: false
        }     
    ]
    licenses: LicenseData[] = []
    latestLicense: LicenseData[] = []
    defaultItem: LicenseData = {}

    get newLicenseForm() {
        return this.editedIndex === -1
    }

    get formTitle () {
        return this.editedIndex === -1 ? 'New License' : 'Edit Item'
    }

    get startDateTimeErrors() : string[] {
        if (!this.editedItem.vpnStartDateTime && !this.editedItem.vpnEndDateTime) return [];
        try {
            if (isNaN((new Date(this.editedItem.vpnStartDateTime)).valueOf())) {
                throw new Error("Invalid date")
            }
        } catch(e) {
            return [e.message]
        }
        return [];
    }

    get actualVpnStartDateTime() : string {
        try {
            return this.editedItem.vpnStartDateTime ? new Date(this.editedItem.vpnStartDateTime).toISOString() : null
        } catch(e) {
            return null
        }
    }

    get endDateTimeErrors() : string[]{
        if (!this.editedItem.vpnStartDateTime && !this.editedItem.vpnEndDateTime) return [];
        try {
            if (isNaN((new Date(this.editedItem.vpnStartDateTime)).valueOf())) {
                throw new Error("Invalid date")
            }
            if (this.editedItem.vpnStartDateTime && !isNaN((new Date(this.editedItem.vpnStartDateTime)).valueOf() )) {
                if ( (new Date(this.editedItem.vpnStartDateTime)).valueOf() > (new Date(this.editedItem.vpnEndDateTime)).valueOf() ) {
                    throw new Error("End date must be after start date")
                }
            }
        } catch(e) {
            return [e.message]
        }
        return [];
    }

    get actualVpnEndDateTime() : string {
        try {
            return this.editedItem.vpnEndDateTime ? new Date(this.editedItem.vpnEndDateTime).toISOString() : null
        } catch(e) {
            return null
        }
    }

    @Watch('pagination', {deep: true})
    onPaginationChanged(val, oldVal) {
        this.refresh()
    }

    @Watch('search')
    onSearchChanged(val, oldVal) {
            this.refresh();
    }

    async refresh() {
        const sortByItem = (this.pagination.sortBy && this.pagination.sortBy.length) ? this.pagination.sortBy[0] : undefined
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination

        try {
            await this.$router.replace({ query: { filter: this.search, itemsPerPage: this.pagination.itemsPerPage, sortBy: this.pagination.sortBy, sortDesc: this.pagination.sortDesc, page: this.pagination.page} })
        } catch(err) {
            console.log("Error pushing new url: " , err)
        }
        const pagedData = await LicenseManagerAxiosInstance.fetchLicenses({
            page: page == 0 ? page : page - 1, 
            pageSize: itemsPerPage,
            filter: this.search,
            orderBy: sortByItem,
            orderDir: (sortDesc && sortDesc[0]) ? 'DESC' : 'ASC'

        } as FetchOptions )
        //this.totalLicenses = pagedData.totalElements
        this.licenses = pagedData.content
        this.loading = false;
        this.licenses = pagedData.content
        this.totalLicenses = pagedData.totalElements
    }

    async generateLicense(serialNumber: string, clientName: string, notes: string, copies: number, vpnStartDate: string, vpnEndDate: string) {
        console.log("copies: " + copies)
        if (!copies || copies <= 0) {
            copies = 1
        }
        this.loading = true;
        this.latestLicenses.splice(0)
        let promises = []
        let vpnStartDateIso = undefined;
        let vpnEndDateIso = undefined;
        try {
            if (vpnStartDate) vpnStartDateIso = (new Date(vpnStartDate)).toISOString();
            if (vpnEndDate) vpnEndDateIso = (new Date(vpnEndDate)).toISOString();
        } catch(e) {}
        for(let i =0; i< copies; i++) {
            promises.push(   LicenseManagerAxiosInstance.createLicense( serialNumber, ""/* BigInt('0x'+ uuidv4().replace(/-/g,'')).toString(36).match(/.{5,5}/g).slice(0,4).join('-').toUpperCase()*/, clientName, notes, vpnStartDateIso, vpnEndDateIso ) )
        }
        this.latestLicenses = await Promise.all(promises)
        console.log(this.latestLicenses)
        this.newLicenseModal = true
        this.refresh();
    }

    newLicense() {
        this.editedIndex = -1
        this.dialog = true
    }

    editItem (item) {
        this.editedIndex = this.licenses.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
    }

    async deleteItem (item) {
        if ( confirm('Are you sure you want to delete this item?') ) {
            this.loading = true;
            await LicenseManagerAxiosInstance.deleteLicense( item.id )
            this.refresh();
        }
    }

    close () {
        this.dialog = false
        setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        }, 300)
    }

    async save () {
        if (this.editedIndex > -1) {
            // edit existing
            this.loading = true;
            this.latestLicense.splice(0)
            this.latestLicense.push( await LicenseManagerAxiosInstance.editLicense( this.editedItem.id, this.editedItem.serialNumber, this.editedItem.clientName, this.editedItem.notes ) )
            this.refresh();
        } else {
            // create new
            this.generateLicense(this.editedItem.serialNumber, this.editedItem.clientName, this.editedItem.notes, this.editedItem.copies, this.editedItem.vpnStartDateTime, this.editedItem.vpnEndDateTime);
        }
        /* if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
            this.desserts.push(this.editedItem)
        } */
        this.close()
    }
    
    mounted () {
        if (this.$router.currentRoute.query.filter) 
            this.search = this.$router.currentRoute.query.filter.toString()
        if (this.$router.currentRoute.query.itemsPerPage) 
            this.pagination.itemsPerPage = parseInt(this.$router.currentRoute.query.itemsPerPage.toString())
        if (this.$router.currentRoute.query.sortBy) 
            this.pagination.sortBy = [ this.$router.currentRoute.query.sortBy.toString() ]
        if ( this.$router.currentRoute.query.sortDesc) 
            this.pagination.sortDesc = [ this.$router.currentRoute.query.sortDesc.toString() == 'true' ? true : false ]
        if (this.$router.currentRoute.query.page) 
            this.pagination.page = parseInt(this.$router.currentRoute.query.page.toString())
    }
}
