//import Keycloak from 'keycloak-js'
import store from '../'


export default async (next, roles) => {
  let keycloakAuth = global.$keycloak
  console.log("keycloak instance??", keycloakAuth)
  let authenticated = await keycloakAuth.init({ onLoad: 'login-required', responseMode: 'fragment', checkLoginIframe: 'false' })
    .catch((error) => {
      console.error("KEYCLOAK INIT ERROR: " + error)
    })
  console.log("AUTHENTICATE?????", authenticated)
  if (!authenticated) {
    window.location.reload()
  }
  console.log("DISPATCH!!!!!!!!!!!!!!!!")
  store.dispatch('authLogin', keycloakAuth)
  if (roles) {
    if (keycloakAuth.hasResourceRole(roles[0])) {
      next()
    }
    else {
      console.log(">>>>>>>>>>>>>>>>>>>>>>>> DISPATCH!!!!!!!!!!!!!!!!")
      next({ name: 'Unauthorized' })
    }
  }
  else {
    //next()
  }
  setInterval(function () {
    console.log("UPDATE TOKEN...")
    keycloakAuth.updateToken(70)
      .success((refreshed) => {
        if (refreshed) {
          store.dispatch('authLogin', keycloakAuth)
        }
        else {
          console.log('Token not refreshed, valid for ' + Math.round(keycloakAuth.tokenParsed.exp + keycloakAuth.timeSkew - new Date().getTime() / 1000) + ' seconds')
        }
      }).error(function () {
        console.error('Failed to refresh token')
      })
  }, 60000)
}
